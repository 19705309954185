<script>

import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

    PageHeader,
  },
  data() {
    return {
      team_member: null,
      searchUsers: null,
      SearchMode: false,
      searchText: "",
      campaigns: [],
      user: {}
    };
  },
  computed: {
    showCampaigns() {
      return this.campaigns;
    },
  },
  methods: {
    navigate(survey) {
      this.$router.push({ name: 'teamMemberCampaignDetails', params: { id: survey?.id } });
    },
    search() {
      this.SearchMode = true;
      if (this.searchText == "") {
        this.campaigns = this.team_member?.marketing_campaign_team_members;
      } else {
        this.campaigns =
          this.team_member?.marketing_campaign_team_members?.filter((i) =>
            i?.marketing_campaign?.name
              ?.toLowerCase()
              ?.includes(this.searchText?.toLowerCase())
          );
        console.log({ campaigns: this.campaigns });
      }
    },
    cancelSearchMode() {
      this.searchUsers = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    getTeamMember() {
      this.http
        .get(
          `marketing-team-members/${this.user?.id}`, null, null, true
        )
        .then((res) => {
          this.team_member = res.data;
          this.campaigns = res?.data?.marketing_campaign_team_members;
          console.log({ campaigns: this.campaigns });
        });
    },
  },
  created() {
    this.user = JSON.parse(localStorage.user)
    this.getTeamMember();
  },
};
</script>

<template>

  <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    :title="$t('menu.menuitems.campaigns.subItems.team_member_campaigns')" />
  <div class="row mb-4">
    <div class="col-4">
      <div class="search-box chat-search-box d-flex align-items-center" style="width: 250px">
        <div class="align-items-center d-flex position-relative mx-3">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')" v-model="searchText"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <!-- <button
              @click="cancelSearchMode()"
              v-if="SearchMode"
              class="btn-close text-light"
              style="position: absolute; top: 12px; left: 250px"
            ></button> -->
        </div>
      </div>
    </div>

    <div class="col-8"></div>
  </div>
  <div class="row">
    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope="col">{{ $t("marketing.survey_list.table.id") }}</th>
          <th scope="col">
            {{ $t("marketing.campaign_list.name") }}
          </th>
          <th scope="col">{{ $t("marketing.campaign_list.descr") }}</th>
          <th scope="col">{{ $t("marketing.campaign_list.start_time") }}</th>
          <th scope="col">{{ $t("marketing.campaign_list.finish_time") }}</th>
          <th scope="col">
            {{ $t("marketing.survey_list.table.details") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(user, index) in showCampaigns" :key="user">
          <td>{{ index + 1 }}</td>
          <td>{{ user?.marketing_campaign?.name }}</td>
          <td>{{ user?.marketing_campaign?.descr }}</td>
          <td>{{ user?.marketing_campaign?.start_time?.split("T")[0] }}</td>
          <td>{{ user?.marketing_campaign?.finish_time?.split("T")[0] }}</td>
          <td>
            <button @click="navigate(user)" class="btn btn-primary btn-sm me-2">
              <i class="bx bx-detail"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- end  table -->

  <!--   Apps  Pagination     -->
  <!-- <ul
      v-if="tot_pages >= 20"
      class="pagination pagination-rounded justify-content-center mb-2"
    >
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul> -->
  <!--end-->

</template>
